// 数据服务
import request from "@/utils/request.js"

// 获取天津市数据
export const getYearData = () => {
  return request({
    method: 'get',
    url: '/getyeardata',
    headers: {
      token: true
    },
    params: {}
  })
}

// 获取所有地区数据
export const getAllAreaData = (date) => {
  return request({
    method: 'get',
    url: '/getregiondata',
    headers: {
      token: true
    },
    params: {
      date
    }
  })
}

// 获取单个地区数据
export const getAreaData = (date, code) => {
  return request({
    method: 'get',
    url: '/getregioninfo',
    headers: {
      token: true
    },
    params: {
      date,
      code
    }
  })
}

// 获取单个地区质押详情数据
export const getAreaPledge = (date, code) => {
  return request({
    method: 'get',
    url: '/getpledgedata',
    headers: {
      token: true
    },
    params: {
      date,
      code
    }
  })
}

