<template>
  <el-row class="area-right">
    <el-col class="data-box right-con-area-1">
      <div class="data-con-title">
        <p class="dct-label">
          {{ dataCondition.map.name }}{{ activeNavName }}数量
        </p>
      </div>
      <line-chart :lineData="lineData" type="area" :navType="navType" />
    </el-col>

    <el-col class="data-box right-con-area-2">
      <div class="data-con-title">
        <p class="dct-label">
          {{ dataCondition.map.name }}{{ activeNavName }}数量企业排名
        </p>
        <p class="dct-info">比较：项目数(件)</p>
      </div>
      <bar-chart :barData="companyData" xLabal="company_name" :sortKey="`${navType}_count`" />
    </el-col>

    <el-col class="data-box right-con-area-3">
      <div class="data-con-title">
        <p class="dct-label">
          {{ dataCondition.map.name }}{{ activeNavName }}项目数银行排名
        </p>
        <p class="dct-info">比较：项目数(件)</p>
      </div>
      <bar-chart :barData="bankData" xLabal="bank_name" :sortKey="`${navType}_count`" />
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import * as dataApi from "@/api/data_service.js";
import BarChart from "./chart/bar.vue";
import LineChart from "./chart/line.vue";
export default {
  name: 'AreaRight',
  props: {
    navType: {
      type: String,
      default: ''
    },
  },
  components: {
    BarChart,
    LineChart,
  },
  data () {
    return {
      lineData: [
        ['product', '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        ['专利'],
        ['商标']
      ],
      companyData: [],
      bankData: [],
    };
  },
  computed: {
    activeNavName () {
      let nameStr = '质押'
      switch (this.navType) {
        case 'patent':
          nameStr = '专利'
          break;
        case 'pledge':
          nameStr = '质押'
          break;
        case 'trademark':
          nameStr = '商标'
          break;
        default:
          break;
      }
      return nameStr
    },
    ...mapGetters([
      'dataCondition',
      'dataAreaDetails'
    ])
  },
  watch: {
    "dataCondition.map.code": {
      handler: function () {
        this.getAreaPledge()
      }
    },
    "dataCondition.year": {
      handler: function () {
        this.getAreaPledge()
      }
    }
  },
  created () {
    if (!Object.keys(this.dataAreaDetails).length || this.dataAreaDetails.region_code != this.dataCondition.map.code) {
      this.getAreaPledge()
    } else {
      for (const key in this.dataAreaDetails) {
        this[key] = this.dataAreaDetails[key]
      }
    }
  },
  methods: {
    async getAreaPledge () {
      try {
        let data = await dataApi.getAreaPledge(this.dataCondition.year, this.dataCondition.map.code)
        let { patent_month, trademark_month, company_data, bank_data } = data
        this.lineData[1].splice(1)
        this.lineData[2].splice(1)
        patent_month.forEach((item, ind) => {
          this.lineData[1].push(item)
          this.lineData[2].push(trademark_month[ind])
        })
        this.companyData = company_data.map(item => {
          item['pledge_count'] = item['patent_count'] + item['trademark_count']
          item['patent_search'] = Array.from(new Set(item['patent_search']));
          item['search'] = Array.from(new Set(item['search']));
          item['trademark_search'] = Array.from(new Set(item['trademark_search']));
          return item
        })
        this.bankData = bank_data.map(item => {
          item['pledge_count'] = item['patent_count'] + item['trademark_count']
          return item
        })
        let patent_project_num = this.lineData[1].slice(1).reduce((prev, item, index, arr) => {
          return prev + item;
        }, 0)
        let trademark_project_num = this.lineData[2].slice(1).reduce((prev, item, index, arr) => {
          return prev + item;
        }, 0)
        this.$store.dispatch('setDataAreaDetails', {
          region_name:this.dataCondition.map.name||'',
          region_code:this.dataCondition.map.code||null,
          lineData: this.lineData,
          companyData: this.companyData,
          bankData: this.bankData,
          pledge_num: this.companyData.length,
          pledge_project_num: patent_project_num + trademark_project_num,
          patent_num: this.companyData.length,
          patent_project_num,
          trademark_num: this.companyData.length,
          trademark_project_num
        })

      } catch (error) {
        return false
      }
    }
  },
};
</script>

<style scoped lang="scss"></style>
