<template>
  <div ref="lineChart" class="line-chart"></div>
</template>

<script>
export default {
  name: 'LineChart',
  props: {
    type: {
      type: String,
      default: 'total'
    },
    lineData: {
      type: Array,
      default: () => []
    },
    navType: {
      type: String,
      default: 'pledge'
    }
  },
  data () {
    return {
      chartDom: null
    }
  },
  watch: {
    lineData: {
      deep: true,
      handler: function () {
        this.draw()
      }
    },
    navType: {
      deep: true,
      handler: function () {
        this.draw()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initEcharts()
    })
  },
  methods: {
    initEcharts () {
      this.chartDom = this.$echarts.init(this.$refs.lineChart)
      this.draw()
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        this.chartDom.resize()
      })
    },
    draw () {
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          textStyle: {
            color: '#fefefe', //字体颜色
          },
          orient: 'vertical', //垂直显示
          y: 'center', //延Y轴居中
          x: 'right', //居右显示
          itemGap: 30, // 图例之间的间距
        },
        grid: {
          top: '20px',
          left: '0px',
          right: '20%',
          bottom: '0px',
          containLabel: true,
        },
        dataset: {
          source: [...this.lineData],
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          axisTick: { show: false, alignWithLabel: true },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#FEFEFE',
            },
          },
          axisLabel: {
            interval: 0,//0表示强制显示所有标签，设置为1，隔一个标签显示一个标签，以此类推。
          }
        },
        yAxis: {
          type: 'value',
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#FEFEFE',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted'
            }
          },
        },
        series: [
          {
            type: 'line',
            symbol: 'circle',
            seriesLayoutBy: 'row',
            label: { show: true, color: '#FEFEFE' },
            itemStyle: {
              color: '#00C6FF',
            },
          },
          {
            type: 'line',
            symbol: 'circle',
            seriesLayoutBy: 'row',
            label: { show: true, color: '#FEFEFE' },
            itemStyle: {
              color: '#34D160',
            },
          },
        ],
      }
      if (this.type === 'total') {
        option.series.push({
          type: 'line',
          symbol: 'circle',
          seriesLayoutBy: 'row',
          label: { show: true, color: '#FEFEFE' },
          itemStyle: {
            color: '#F19610',
          },
        })
      } else if (this.type === 'area') {
        option.legend['show'] = false;
        option.grid['right'] = '0%';
        option.xAxis.axisTick['show'] = true
        option.yAxis.axisLine['show'] = false
        option.yAxis.splitLine['show'] = true
        if (this.navType === 'patent') {
          option.dataset.source.splice(2, 1)
          option.series.splice(1, 1)
        } else if (this.navType === 'trademark') {
          option.dataset.source.splice(1, 1)
          option.series.splice(0, 1)
        }
      }
      this.chartDom.setOption(option, true)
    }
  },
}
</script>
<style lang="scss" scope>
.line-chart {
  width: 100%;
  height: 100%;
}
</style>
