<template>
  <div ref="barChart" class="bar-chart"></div>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    // 数据
    barData: {
      type: Array,
      default: () => []
    },
    // x轴标签
    xLabal: {
      type: String,
      default: "region_name"
    },
    // 排序字段
    sortKey: {
      type: String,
      default: 'pledge'
    },
    // 保留长度
    length: {
      type: Number || String,
      default: 5
    },
    // 是否是详情页柱状图
    isDetails: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    barData: {
      deep: true,
      handler: function () {
        this.draw()
      }
    },
    sortKey: {
      deep: true,
      handler: function () {
        this.draw()
      }
    },
  },
  data () {
    return {
      chartDom: null,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.initEcharts();
    });
    setTimeout(() => {
      this.chartDom.resize();
    }, 500)
  },
  methods: {
    initEcharts () {
      this.chartDom = this.$echarts.init(this.$refs.barChart);
      this.draw()
      this.chartDom.on("click", (params) => {
        this.$emit('gotoDetails', this.sortKey)
      })
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.chartDom.resize();
      });
    },
    draw () {
      let xData = []
      let yData = []
      const dataVal = JSON.parse(JSON.stringify(this.barData || []))
      const sortedData = dataVal.sort((a, b) => { return b[this.sortKey] - a[this.sortKey] }).splice(0, this.length);
      for (let i = 0; i < sortedData.length; i++) {
        xData.push(sortedData[i][this.xLabal])
        yData.push(sortedData[i][this.sortKey])
      }
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}：{c}',
        },
        xAxis: {
          // type: 'category',
          data: xData,
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: '#FEFEFE',//左边线的颜色
            }
          },
          axisLabel: {
            color: '#FEFEFE',//坐标值得具体的颜色
            fontSize: this.isDetails ? 16 : 12,
            interval: 0,//0表示强制显示所有标签，设置为1，隔一个标签显示一个标签，以此类推。
            formatter: function (value) {
              // debugger
              var ret = "";//拼接加\n返回的类目项  
              var maxLength = 6;//每项显示文字个数  
              var valLength = value.length;//X轴类目项的文字个数  
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
              //如果类目项的文字大于3,  
              if (rowN > 1) {
                for (var i = 0; i < rowN; i++) {
                  var temp = "";//每次截取的字符串  
                  var start = i * maxLength;//开始截取的位置  
                  var end = start + maxLength;//结束截取的位置  
                  //这里也可以加一个是否是最后一行的判断
                  if (i == rowN - 1) {
                    // 最后一次不换行
                    temp = value.substring(start, end);
                  } else {
                    // 每一次拼接字符串并换行
                    temp = value.substring(start, end) + "\n";
                  }
                  ret += temp; //凭借最终的字符串  
                }
                return ret;
              }
              else {
                return value;
              }
            }
          }
        },
        yAxis: {
          axisLabel: {
            show: this.isDetails ? true : false,
            color: '#FEFEFE',//坐标值得具体的颜色
            fontSize: 16,
          },
          axisTick: { show: false },
          axisLine: { show: false, },
          splitLine: {
            show: this.isDetails ? true : false,
            lineStyle: { color: '#626B84' }
          }
        },
        grid: {
          top: "22px",
          left: "0%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            type: "bar", //形状为柱状图
            data: yData,
            color: '#00C6FF',
            barWidth: 26,
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              color: '#fefefe',
              fontSize: 16
            },
          }
        ]
      };
      this.chartDom.setOption(option);
    },
  },
};
</script>
<style lang="scss" scope>
.bar-chart {
  width: 100%;
  height: 100%;
}
</style>
